import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeSection from "../components/sections/HomeSections"
import AboutSection from "../components/sections/AboutSection"
import ContactSection from "../components/sections/ContactSection"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeSection />
    <AboutSection />
    <ContactSection />
  </Layout>
)

export default IndexPage

import React from "react"
import styled from "styled-components"
import { H1, P } from "../theme"
import GridComponent from "../GridComponent"
import S2 from "../../images/homeSmall.jpg"

const Container = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
  /* margin-top: 170px; */
`

const EmsBackSection = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #191919;
`

const EmsContainer = styled.div`
  /* margin-top: 170px; */
  width: 100%;
  /* height: 800px; */
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    height: auto;
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  /* background-image: url("./homeSmall.jpg");
  background-size: cover;
  width: 100%; */
  z-index: 5;
  img {
    filter: brightness(70%);
    margin: 0 !important;
  }
  @media screen and (max-width: 1200px) {
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  /* @media screen and (max-width: 650px) {
    width: 200%;
  } */
`

const Left = styled.div`
  z-index: 2;
  flex-basis: 50%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 160px;
  h1 {
    font-size: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 1750px) {
    padding: 70px;
  }
  @media screen and (max-width: 1500px) {
    padding: 50px;
  }
  @media screen and (max-width: 1250px) {
    padding: 40px;
  }
  @media screen and (max-width: 1200px) {
    text-align: center;
    h1 {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 550px) {
    padding: 2%;
    h1 {
      font-size: 30px;
    }
  }
`

const Right = styled.div`
  flex-basis: 50%;
  overflow: hidden;
  img {
    float: right;
  }
  /* @media screen and (max-width: 650px) {
    img {
      float: none;
    }
  } */
`

const AboutSection = () => {
  return (
    <Container id="ems">
      <GridComponent />
      <EmsBackSection />
      <EmsContainer>
        <Left>
          <H1>TRENING PO MERI SAVREMENOG VEŽBAČA</H1>
          <P>
            Dobro došli u Fast Fit, inovativni fitnes koncept koji postavlja
            visoke standarde.
          </P>
          <P>
            Fast Fit nije običan fitnes studio, Fast Fit je filozofija koja
            promoviše zdrav način života uzimajući u obzir moderan životni stil
            vežbača i manjak vremena koji je kod svih poslovnih ljudi postao
            deficitaran resurs.
          </P>
          <P>
            Među prvima smo se na ovim prostorima opredelili za korišćenje
            inovativne EMS tehnologije u trenažnom procesu, tehnologije koja
            skraćuje trening istovremeno ga čineći još efikasnijim. Od starta
            smo visoko postavili lestvicu, kako u pogledu uslova za trening,
            tako i u pogledu kvaliteta rada trenera sa vežbačima, a to je
            prepoznao i Savez za rekreaciju i fitnes Srbije nagradivši nas za
            najbolji fitnes studio u Srbiji za 2019. godinu.
          </P>
          {/* <P>
            Glavna misija Fast Fit-a je da upravo kroz personalizovan pristup
            spoji sve ono što je današnjem poslovnom čoveku potrebno kroz
            redovan i adekvatan trening.
          </P> */}
          <P>
            Konstantno rastemo i u ovom trenutku poslujemo na dve lokacije u
            Beogradu, izaberi onu koja te interesuje i saznaj više.
          </P>
        </Left>
        {/* <Right>
          <ImageContainer>
            <img className="testImage2" src={S2} alt="" />
          </ImageContainer>
        </Right> */}
        <Right>
          {/* <div style={{ backgroundImage: "url('homeSmall.jpg')" }}></div> */}
          <ImageContainer>
            <img className="testImage2" src={S2} alt="" />
          </ImageContainer>
        </Right>
        {/* <button>test1</button>
        <button>test2</button> */}
      </EmsContainer>
    </Container>
  )
}

export default AboutSection
